import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from './components/Root/Root';
import reportWebVitals from './reportWebVitals';

import './common/styles/main.sass';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<Root />);

reportWebVitals();
