import React, { useState, MouseEvent } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useKeycloak } from '@react-keycloak/web';
import { blueGrey } from '@mui/material/colors';
import { useAppSelector } from '../../hooks/redux';
import { StateName } from '../../redux/types';

const useStyles = makeStyles((theme) => createStyles({
  panel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  avatar: {
    marginLeft: 10,
    backgroundColor: theme.palette.getContrastText(blueGrey[500]),
    color: blueGrey[500],
  },
  button: {
    color: '#fff !important',
  },
}));

function UserPanel() {
  const classes = useStyles();
  const keycloak = useKeycloak();
  const username = useAppSelector((state) => state[StateName.Auth].username);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const getInitials = (name: string): string => {
    const nameArr = name?.split(' ');

    if (typeof nameArr === 'object') {
      const firstLettersArr = nameArr.map((word) => word[0]);

      return firstLettersArr.join('').toUpperCase();
    }

    return '';
  };

  const dialogHandler = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const dialogCloseHandler = () => {
    setAnchorEl(null);
  };

  const logOutHandler = () => {
    keycloak.keycloak?.logout();
  };

  return (
    <div className={classes.panel}>
      <Button onClick={dialogHandler} size="small" className={classes.button}>{username}</Button>
      <Avatar className={classes.avatar}>{getInitials(username)}</Avatar>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={dialogCloseHandler}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ transform: 'translateY(40px)' }}
      >
        <MenuItem onClick={logOutHandler}>Выйти</MenuItem>
      </Menu>
    </div>
  );
}

export default UserPanel;
