import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './services/authSlice';
import appSlice from './services/appSlice';

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [appSlice.name]: appSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
