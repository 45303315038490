import React from 'react';
import { Link } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {
  createStyles,
  makeStyles,
} from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import clsx from 'clsx';
import UserPanel from '../UserPanel/UserPanel';
import { StateName } from '../../redux/types';
import { useAppSelector } from '../../hooks/redux';

const useStyles = makeStyles(() => createStyles({
  link: {
    textDecoration: 'none',
    color: '#fff',
  },
  icon: {
    marginRight: '26px',
  },
  iconHidden: {
    display: 'none',
  },
}));

interface IHeader {
  isOpen: boolean;
  handleDrawerOpen: () => void;
}

function Header({ isOpen, handleDrawerOpen }: IHeader) {
  const classes = useStyles();
  const isAuth = useAppSelector((state) => state[StateName.Auth].isAuth);

  return (
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        classes={{
          root: clsx(classes.icon, {
            [classes.iconHidden]: isOpen,
          }),
        }}
      >
        <MenuIcon />
      </IconButton>
      <Link to="/" className={classes.link}>
        <Typography variant="h6" noWrap>
          Dashboard
        </Typography>
      </Link>
      {isAuth && <UserPanel />}
    </Toolbar>
  );
}

export default Header;
