import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles(() => createStyles({
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogContent: {
    padding: '8px 24px',
  },
  closeButton: {
    margin: '0 15px',
  },
  submitButton: {
    marginLeft: '15px',
  },
}));
