import React, { Dispatch, SetStateAction } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import useDialogStyles from 'hooks/useDialogStyles';
import { useAppDispatch } from 'hooks/redux';
import { setError } from 'redux/services/appSlice';
import { Errors } from 'redux/types';

interface IDialogDelete {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  errors?: Errors;
}

function DialogError({
  isOpen, setIsOpen, errors, title,
}: IDialogDelete) {
  const dialogClasses = useDialogStyles();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setError({ errors: null }));
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <header className={dialogClasses.dialogHeader}>
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          size="small"
          onClick={handleClose}
          className={dialogClasses.closeButton}
        >
          <Close />
        </IconButton>
      </header>
      <DialogContent dividers>
        {errors && errors.map((error, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <DialogContentText key={index}>{error}</DialogContentText>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogError;
