import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import { makeStyles, createStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import { useKeycloak } from '@react-keycloak/web';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setAuth, setUserName } from 'redux/services/authSlice';
import { StateName } from 'redux/types';
import Routes from '../Routes/Routes';
import Navigation from '../Navigation/Navigation';
import Header from '../Header/Header';
import DialogError from '../dialogs/DialogError/DialogError';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flex: '1 1',
  },
  appBarUnshift: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  paper: {
    display: 'block',
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 0),
    maxWidth: 'calc(100% - 73px)',
  },
}));

function App() {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const errors = useAppSelector((state) => state[StateName.App].errors);
  const { keycloak, initialized } = useKeycloak();

  const handleDrawerOpen = (): void => {
    setIsOpen(true);
  };

  const handleDrawerClose = (): void => {
    setIsOpen(false);
  };

  const renderRoutesOrAuth = () => {
    if (!initialized) return <LinearProgress />;
    if (keycloak?.authenticated) {
      return <Routes />;
    }

    keycloak?.login();
    return null;
  };

  useEffect(() => {
    if (errors) {
      setIsErrorDialogOpen(true);
    }
  }, [errors]);

  useEffect(() => {
    if (keycloak?.authenticated) {
      dispatch(setAuth({ isAuth: true }));
      dispatch(setUserName({ username: keycloak.tokenParsed?.name }));
    }
  }, [keycloak?.authenticated]);

  return (
    <div className={classes.root}>
      <DialogError
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        title="Ошибка"
        errors={errors}
      />
      <CssBaseline />
      <AppBar
        position="fixed"
        classes={{
          root: clsx({
            [classes.appBarShift]: isOpen,
            [classes.appBarUnshift]: !isOpen,
          }),
        }}
      >
        <Header isOpen={isOpen} handleDrawerOpen={handleDrawerOpen} />
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.paper, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
          root: clsx(classes.drawer, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft />
          </IconButton>
        </div>
        <Navigation />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {renderRoutesOrAuth()}
      </main>
    </div>
  );
}

export default App;
