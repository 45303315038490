/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthState, StateName } from '../types';

const initialState: IAuthState = {
  isAuth: false,
  username: '',
};

const authSlice = createSlice({
  name: StateName.Auth,
  initialState,
  reducers: {
    setAuth: (state: IAuthState, action: PayloadAction<{ isAuth: boolean }>) => {
      state.isAuth = action.payload.isAuth;
    },
    setUserName: (state: IAuthState, action: PayloadAction<{ username: string }>) => {
      state.username = action.payload.username;
    },
  },
});

export const {
  setAuth,
  setUserName,
} = authSlice.actions;

export default authSlice;
