import React from 'react';
import Link from '@mui/material/Link';

function NotFoundPage() {
  return (
    <div style={{ textAlign: 'center', textShadow: '10px 10px 10px rgba(0, 0, 0, .2)' }}>
      <span style={{ fontSize: '160px', lineHeight: 1 }}>404</span>
      <br />
      <span style={{ fontSize: '24px' }}>Страница не найдена</span>
      <p><Link href="/">На главную</Link></p>
    </div>
  );
}

export default NotFoundPage;
