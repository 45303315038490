import React, { Suspense, lazy } from 'react';
import { Route, Routes as SwitchRoutes } from 'react-router-dom';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import NotFoundPage from '../../pages/NotFoundPage';
import { Paths } from '../../constants/constants';

const ServicesPage = lazy(() => import('../../pages/ServicesPage'));
const GroupsPage = lazy(() => import('../../pages/GroupsPage'));
const ContentsPage = lazy(() => import('../../pages/ContentsPage'));
const ContentsByGroupPage = lazy(() => import('../../pages/ContentsByGroupPage'));
const ContentPage = lazy(() => import('../../pages/ContentPage'));
const PointsByServicePage = lazy(() => import('../../pages/PointsByServicePage'));
const CategoriesByServicePage = lazy(() => import('../../pages/CategoriesByServicePage'));
const CategoriesByServiceDetailPage = lazy(() => import('../../pages/CategoriesByServiceDetailPage'));
const PreviewControlPage = lazy(() => import('../../pages/PreviewControlPage'));
const GroupsByContentPage = lazy(() => import('../../pages/GroupsByContentPage'));
const GroupsSelectionPage = lazy(() => import('../../pages/GroupsSelectionPage'));
const TagsPage = lazy(() => import('../../pages/TagsPage'));
const TagsDetailPage = lazy(() => import('../../pages/TagsDetailPage'));
const ContentsByCategoryPage = lazy(() => import('../../pages/ContentsByCategoryPage'));
const TagsByContentPage = lazy(() => import('../../pages/TagsByContentPage'));
const ContentsByTagPage = lazy(() => import('../../pages/ContentsByTagPage'));
const ContentsByGroupSelectionPage = lazy(() => import('../../pages/ContentsByGroupSelectionPage'));
const PointsPage = lazy(() => import('../../pages/PointsPage'));
const AboutPage = lazy(() => import('../../pages/AboutPage'));
const ContentsByCategoryExcludePage = lazy(() => import('../../pages/ContentsByCategoryExcludePage'));
const StatPage = lazy(() => import('../../pages/StatPage'));

function Routes() {
  return (
    <Container maxWidth="xl">
      <Suspense fallback={<LinearProgress />}>
        <SwitchRoutes>
          <Route path={Paths.PointsByService} element={<PointsByServicePage />} key={Paths.PointsByService} />
          <Route path={Paths.CategoriesByServiceDetail} element={<CategoriesByServiceDetailPage />} key={Paths.CategoriesByServiceDetail} />
          <Route path={Paths.CategoriesByService} element={<CategoriesByServicePage />} key={Paths.CategoriesByService} />
          <Route path={Paths.GroupsSelection} element={<GroupsSelectionPage />} key={Paths.GroupsSelection} />
          <Route path={Paths.ContentsByCategory} element={<ContentsByCategoryPage />} key={Paths.ContentsByCategory} />
          <Route path={Paths.ContentsByCategoryExclude} element={<ContentsByCategoryExcludePage />} key={Paths.ContentsByCategoryExclude} />
          <Route path={Paths.Groups} element={<GroupsPage />} key={Paths.Groups} />
          <Route path={Paths.ContentsByGroup} element={<ContentsByGroupPage />} key={Paths.ContentsByGroup} />
          <Route path={Paths.ContentsByGroupSelection} element={<ContentsByGroupSelectionPage />} key={Paths.ContentsByGroupSelection} />
          <Route path={Paths.PreviewControl} element={<PreviewControlPage />} key={Paths.PreviewControl} />
          <Route path={Paths.GroupsByContent} element={<GroupsByContentPage />} key={Paths.GroupsByContent} />
          <Route path={Paths.TagsByContent} element={<TagsByContentPage />} key={Paths.TagsByContent} />
          <Route path={Paths.Content} element={<ContentPage />} key={Paths.Content} />
          <Route path={Paths.ContentsByTag} element={<ContentsByTagPage />} key={Paths.ContentsByTag} />
          <Route path={Paths.TagsDetail} element={<TagsDetailPage />} key={Paths.TagsDetail} />
          <Route path={Paths.Tags} element={<TagsPage />} key={Paths.Tags} />
          <Route path={Paths.Points} element={<PointsPage />} key={Paths.Points} />
          <Route path={Paths.Contents} element={<ContentsPage />} key={Paths.Contents} />
          <Route path={Paths.Stat} element={<StatPage />} key={Paths.Stat} />
          <Route path={Paths.About} element={<AboutPage />} key={Paths.About} />
          <Route path={Paths.Home} element={<ServicesPage />} key={Paths.Home} />
          <Route path="*" element={<NotFoundPage />} key="not-found" />
        </SwitchRoutes>
      </Suspense>
    </Container>
  );
}

export default Routes;
