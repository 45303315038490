import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
  url: 'https://b3w-keycloak.adm4b.com/auth',
  realm: 'master',
  clientId: 'contents',
});

export default keycloak;
