import React from 'react';
import { Provider } from 'react-redux';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import {
  createTheme, ThemeProvider, Theme, StyledEngineProvider,
} from '@mui/material/styles';
import { blueGrey, purple } from '@mui/material/colors';
import { store } from '../../redux/store';
import keycloakService from '../../helpers/keycloak';
import ApolloProviderWrapper from '../ApolloProviderWrapper/ApolloProviderWrapper';

// need to add a module augmentation for the DefaultTheme
declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

function Root() {
  const finalTheme = createTheme({
    palette: {
      primary: {
        main: blueGrey[500],
        contrastText: '#fff',
      },
      secondary: {
        main: purple[300],
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1920,
      },
    },
  });
  return (
    <Provider store={store}>
      <ReactKeycloakProvider authClient={keycloakService}>
        <ThemeProvider theme={finalTheme}>
          <StyledEngineProvider injectFirst>
            <ApolloProviderWrapper />
          </StyledEngineProvider>
        </ThemeProvider>
      </ReactKeycloakProvider>
    </Provider>
  );
}

export default Root;
