export type IsAuth = boolean;
export type UserName = string;
export type RowsPerPage = number;
export type PreviewText = string | null;
export type NavCurrent = string;
export type Errors = string[] | undefined | null;

export interface IAuthState {
  isAuth: IsAuth;
  username: UserName;
}
export interface IAppState {
  rowsPerPage: RowsPerPage;
  previewText: PreviewText;
  navCurrent: NavCurrent;
  selectedPointsId: number[];
  errors: Errors;
}
export const enum StateName {
  Auth = 'auth',
  App = 'app',
}
