export const enum Paths {
  Home = '/',
  PointsByService = '/service/:id/points',
  CategoriesByServiceDetail = '/service/:id/categories/:categoryId',
  CategoriesByService = '/service/:id/categories',
  GroupsSelection = '/category/:id/groups',
  ContentsByCategory = '/category/:id/contents',
  ContentsByCategoryExclude = '/category/:id/contents-selection',
  Groups = '/groups',
  ContentsByGroup = '/group/:id/contents',
  ContentsByGroupSelection = '/group/:id/contents-selection',
  PreviewControl = '/content/:id/preview',
  GroupsByContent = '/content/:id/groups',
  TagsByContent = '/content/:id/tags',
  Content = '/content/:id',
  ContentsByTag = '/tag/:id/contents',
  TagsDetail = '/tags/:id',
  Tags = '/tags',
  Points = '/points',
  Contents = '/contents',
  Stat = '/stat',
  About = '/about',
}

export const enum TableTypes {
  Services = 'services',
  Points = 'points',
  PointsAccess = 'pointsAccess',
  PointsByService = 'pointsByService',
  Categories = 'categories',
  GroupLinks = 'groupLinks',
  Groups = 'groups',
  ContentGroupLinks = 'contentGroupLinks',
  ContentsByCategory = 'contentsByCategory',
  ContentsByGroup = 'contentsByGroup',
  ContentsByTag = 'contentsByTag',
  ContentCategoryLinks = 'contentCategoryLinks',
  Contents = 'contents',
  Tags = 'tags',
  TagsByContent = 'tagsByContent',
  GroupsByContent = 'groupsByContent',
}
