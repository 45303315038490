/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import {
  Errors,
  IAppState,
  NavCurrent,
  PreviewText,
  StateName,
} from '../types';

const initialState: IAppState = {
  rowsPerPage: 20,
  previewText: null,
  navCurrent: '',
  selectedPointsId: [],
  errors: undefined,
};

export const fetchPreviewText = createAsyncThunk(
  `${StateName.App}/fetchPreviewText`,
  async ({ text, token }: { text: PreviewText, token: string }) => {
    if (text) {
      return axios.post(
        '/api/preview-html',
        text,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      )
        .then((response: AxiosResponse<string>) => {
          if (response.status === 200) {
            return response.data as PreviewText;
          }
          return null;
        })
        .catch(() => null);
    }
    return null;
  },
);

const appSlice = createSlice({
  name: StateName.App,
  initialState,
  reducers: {
    setRowsPerPage: (state: IAppState, action: PayloadAction<{ rows: number }>) => {
      state.rowsPerPage = action.payload.rows;
    },
    setNavCurrent: (state: IAppState, action: PayloadAction<{ navCurrent: NavCurrent }>) => {
      state.navCurrent = action.payload.navCurrent;
    },
    addSelectedPoint: (state: IAppState, action: PayloadAction<number>) => {
      state.selectedPointsId.push(action.payload);
    },
    removeSelectedPoint: (state: IAppState, action: PayloadAction<number>) => {
      state.selectedPointsId = state.selectedPointsId.filter((pointId) => pointId !== action.payload);
    },
    setError: (state: IAppState, action: PayloadAction<{ errors: Errors }>) => {
      state.errors = action.payload.errors;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPreviewText.fulfilled, (state, action) => {
      state.previewText = action.payload;
    });
  },
});

export const {
  setRowsPerPage,
  setNavCurrent,
  addSelectedPoint,
  removeSelectedPoint,
  setError,
} = appSlice.actions;

export default appSlice;
