import React from 'react';
import {
  ApolloClient, ApolloLink, ApolloProvider, from, InMemoryCache,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { BrowserRouter } from 'react-router-dom';
import { onError } from '@apollo/client/link/error';
import App from '../App/App';
import keycloakService from '../../helpers/keycloak';
import { useAppDispatch } from '../../hooks/redux';
import { setError } from '../../redux/services/appSlice';
import {
  ServiceFragment,
  PointFragment,
  CategoryByServiceIdFragment,
  ContentGroupLinkFragment,
  ContentTableFragment,
  TagFragment,
  ContentTagLinkTableFragment,
  GroupContentLinkFragment,
  ContentCategoryLinkFragment,
  GroupCategoryLink, TagContentLinkFragment, ContentFragment,
} from '../../generated/graphql';

function ApolloProviderWrapper() {
  // необходимо поставить версию @apollo/client, которая совпадает с версией @apollo/client в @types/apollo-upload-client
  const link = createUploadLink({ uri: '/api/query' });

  const dispatch = useAppDispatch();

  const errorMiddleware = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      dispatch(setError({
        errors: graphQLErrors.map(
          ({ message, locations, path }) => `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      }));
    }

    // eslint-disable-next-line no-console
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    const kcToken = keycloakService?.token ?? '';
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${kcToken}`,
      },
    }));
    return forward(operation);
  });

  function mergeCallback<T>(
    existing: Array<T | null>,
    incoming: Array<T>,
    args: Record<string, any> | null,
  ) {
    const merged = existing ? existing.slice(0) : [];
    const offset = args?.offset || 0;
    const limit = args?.limit || 20;
    // checking previous array part length
    const prevArrayPart = merged.slice(0, offset);
    if (prevArrayPart.length < offset) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < offset; ++i) {
        merged[i] = null;
      }
    }
    const end = offset + Math.min(limit, incoming.length);
    // eslint-disable-next-line no-plusplus
    for (let i = offset; i < end; ++i) {
      merged[i] = incoming[i - offset];
    }
    if (limit > incoming.length) { // проверка на уменьшение длины массива элементов
      return merged.slice(0, end);
    }
    return merged;
  }

  const client = new ApolloClient({
    link: from([errorMiddleware, authMiddleware, link]),
    cache: new InMemoryCache({
      typePolicies: {
        ContentGroupLink: {
          keyFields: ['content', ['id']],
        },
        ContentTagLink: {
          keyFields: ['content', ['id']],
        },
        GroupContentLink: {
          keyFields: ['group', ['id']],
        },
        GroupCategoryLink: {
          keyFields: ['group', ['id']],
        },
        TagContentLink: {
          keyFields: ['tag', ['id']],
        },
        ContentCategoryLink: {
          keyFields: ['content', ['id']],
        },
        Service: {
          fields: {
            points: {
              keyArgs: ['searches', 'sort'],
              merge(existing: Array<PointFragment | null>, incoming: Array<PointFragment>, { args }) {
                return mergeCallback<PointFragment>(existing, incoming, args);
              },
            },
          },
        },
        Query: {
          fields: {
            services: {
              keyArgs: ['searches', 'sort'],
              merge(existing: Array<ServiceFragment | null>, incoming: Array<ServiceFragment>, { args }) {
                return mergeCallback<ServiceFragment>(existing, incoming, args);
              },
            },
            points: {
              keyArgs: ['searches', 'sort'],
              merge(existing: Array<PointFragment | null>, incoming: Array<PointFragment>, { args }) {
                return mergeCallback<PointFragment>(existing, incoming, args);
              },
            },
            categoriesByServiceId: {
              keyArgs: ['serviceId', 'searches', 'sort'],
              merge(existing: Array<CategoryByServiceIdFragment | null>, incoming: Array<CategoryByServiceIdFragment>, { args }) {
                return mergeCallback<CategoryByServiceIdFragment>(existing, incoming, args);
              },
            },
            groups: {
              keyArgs: ['searches', 'sort'],
              merge(existing: Array<CategoryByServiceIdFragment | null>, incoming: Array<CategoryByServiceIdFragment>, { args }) {
                return mergeCallback<CategoryByServiceIdFragment>(existing, incoming, args);
              },
            },
            contentGroupLinksByGroupId: {
              keyArgs: ['groupId', 'searches', 'sort'],
              merge(existing: Array<ContentGroupLinkFragment | null>, incoming: Array<ContentGroupLinkFragment>, { args }) {
                return mergeCallback<ContentGroupLinkFragment>(existing, incoming, args);
              },
            },
            contentsByGroupId: {
              keyArgs: ['groupId', 'searches', 'sort'],
              merge(existing: Array<ContentTableFragment | null>, incoming: Array<ContentTableFragment>, { args }) {
                return mergeCallback<ContentTableFragment>(existing, incoming, args);
              },
            },
            contents: {
              keyArgs: ['searches', 'sort'],
              merge(existing: Array<ContentTableFragment | null>, incoming: Array<ContentTableFragment>, { args }) {
                return mergeCallback<ContentTableFragment>(existing, incoming, args);
              },
            },
            tags: {
              keyArgs: ['searches', 'sort'],
              merge(existing: Array<TagFragment | null>, incoming: Array<TagFragment>, { args }) {
                return mergeCallback<TagFragment>(existing, incoming, args);
              },
            },
            contentTagLinksByTagId: {
              keyArgs: ['tagId', 'searches', 'sort'],
              merge(existing: Array<ContentTagLinkTableFragment | null>, incoming: Array<ContentTagLinkTableFragment>, { args }) {
                return mergeCallback<ContentTagLinkTableFragment>(existing, incoming, args);
              },
            },
            contentsByCategoryId: {
              keyArgs: ['categoryId', 'searches', 'sort'],
              merge(existing: Array<ContentFragment | null>, incoming: Array<ContentFragment>, { args }) {
                return mergeCallback<ContentFragment>(existing, incoming, args);
              },
            },
            contentCategoryLinks: {
              keyArgs: ['categoryId', 'searches', 'sort'],
              merge(existing: Array<ContentCategoryLinkFragment | null>, incoming: Array<ContentCategoryLinkFragment>, { args }) {
                return mergeCallback<ContentCategoryLinkFragment>(existing, incoming, args);
              },
            },
            groupContentLinksByContentId: {
              keyArgs: ['contentId', 'searches', 'sort'],
              merge(existing: Array<GroupContentLinkFragment | null>, incoming: Array<GroupContentLinkFragment>, { args }) {
                return mergeCallback<GroupContentLinkFragment>(existing, incoming, args);
              },
            },
            groupCategoryLinksByCategoryId: {
              keyArgs: ['categoryId', 'searches', 'sort'],
              merge(existing: Array<GroupCategoryLink | null>, incoming: Array<GroupCategoryLink>, { args }) {
                return mergeCallback<GroupCategoryLink>(existing, incoming, args);
              },
            },
            tagContentLinksByContentId: {
              keyArgs: ['contentId', 'searches', 'sort'],
              merge(existing: Array<TagContentLinkFragment | null>, incoming: Array<TagContentLinkFragment>, { args }) {
                return mergeCallback<TagContentLinkFragment>(existing, incoming, args);
              },
            },
          },
        },
      },
    }),
  });
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default ApolloProviderWrapper;
