import React from 'react';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import Apps from '@mui/icons-material/Apps';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import LocalOffer from '@mui/icons-material/LocalOffer';
import VerticalSplit from '@mui/icons-material/VerticalSplit';
import Code from '@mui/icons-material/Code';
import HelpIcon from '@mui/icons-material/Help';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useAppSelector } from '../../hooks/redux';
import { StateName } from '../../redux/types';

function Navigation() {
  const navigate = useNavigate();
  const navCurrent = useAppSelector((state) => state[StateName.App].navCurrent);

  const changeRoute = (route: string) => () => {
    navigate(route);
  };

  return (
    <>
      <Divider />
      <ListItemButton selected={navCurrent === 'services'} onClick={changeRoute('/')}>
        <Tooltip title="Сервисы">
          <ListItemIcon>
            <Apps />
          </ListItemIcon>
        </Tooltip>
        <ListItemText>
          Сервисы
        </ListItemText>
      </ListItemButton>
      <ListItemButton selected={navCurrent === 'groups'} onClick={changeRoute('/groups')}>
        <Tooltip title="Группы">
          <ListItemIcon>
            <VerticalSplit />
          </ListItemIcon>
        </Tooltip>
        <ListItemText>
          Группы
        </ListItemText>
      </ListItemButton>
      <ListItemButton selected={navCurrent === 'contents'} onClick={changeRoute('/contents')}>
        <Tooltip title="Контент">
          <ListItemIcon>
            <Code />
          </ListItemIcon>
        </Tooltip>
        <ListItemText>
          Контент
        </ListItemText>
      </ListItemButton>
      <ListItemButton selected={navCurrent === 'tags'} onClick={changeRoute('/tags')}>
        <Tooltip title="Метки">
          <ListItemIcon>
            <LocalOffer />
          </ListItemIcon>
        </Tooltip>
        <ListItemText>
          Метки
        </ListItemText>
      </ListItemButton>
      <ListItemButton selected={navCurrent === 'points'} onClick={changeRoute('/points')}>
        <Tooltip title="SSKEY">
          <ListItemIcon>
            <CompareArrowsIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText>
          SSKEY
        </ListItemText>
      </ListItemButton>
      <ListItemButton selected={navCurrent === 'stat'} onClick={changeRoute('/stat')}>
        <Tooltip title="Статистика">
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText>
          Статистика
        </ListItemText>
      </ListItemButton>
      <ListItemButton selected={navCurrent === 'about'} onClick={changeRoute('/about')}>
        <Tooltip title="Как работает">
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText>
          Как работает
        </ListItemText>
      </ListItemButton>
    </>
  );
}

export default Navigation;
